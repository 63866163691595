import { css } from "@emotion/react"
import { graphql, Link, StaticQuery } from "gatsby"
import parse from "html-react-parser"
import React, { useEffect, useRef, useState } from "react"
import { Col, Container, Dropdown, Modal, Row } from "react-bootstrap"
import LazyLoad from "react-lazyload"
import Fade from "react-reveal/Fade"
import EsArrowDown from "../../assets/images/resources/es-arrow-down.png"
import CTA from "../../components/common/CTA"
import Frame from "../../components/common/frame"
import SVGIcon from "../../components/common/SVGIcon"
import Navigation from "../../components/navigation"
import "../../styles/pages/customers.scss"
import Close from "./../../assets/images/close-white.png"

const FiveStar = () => {
  let star = []
  for (var ind = 0; ind < 5; ind++) {
    star.push(
      <Fade delay={ind * 100}>
        <SVGIcon name="philosophy/star" className="star-icon" />
      </Fade>
    )
  }
  return star
}
const CaseStudyVideo = ({ item }) => {
  const [show, setShowVideo] = useState(false)
  const handleClose = () => {
    setShowVideo(false)
  }
  const handleShow = () => {
    setShowVideo(true)
  }

  const { title, chapterNumber, image } = item
  return (
    <div className="cs-video mb24 br10 hook-up">
      <Col
        lg={12}
        onClick={() => {
          handleShow()
        }}
      >
        <div className="cover position-relative">
          <LazyLoad once offset={100}>
            <img src={image?.url} alt={title} className="w-100" />
          </LazyLoad>
          <SVGIcon
            name="play_btn_square"
            className="play-btn position-absolute"
          />
        </div>
        <div className="content position-relative">
          <p className="text-pink p12 let-space-4 fw-500">CASE STUDY</p>
          <p className="title fw-bold font-roboto mb24"> {title} </p>
          <p className="link">
            watch video
            <SVGIcon name="hypertext-arrow-right" className="arrow-right" />
          </p>
        </div>
      </Col>
      <Modal
        className="yt"
        show={show}
        onHide={() => setShowVideo(false)}
        centered
        dialogClassName="modal-80w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <img
          src={Close}
          alt="close"
          className="close-icn"
          onClick={handleClose}
          decoding="async"
        />
        <Container className="yt-vdo">
          <iframe
            className="video"
            src={`${chapterNumber}?rel=0&autoplay=1`}
            frameborder="0"
            allowfullscreen="allowfullscreen"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </Container>
      </Modal>
    </div>
  )
}
const CaseStudyText = ({ item }) => {
  const { title, chapterTitle, image, slug } = item
  return (
    <Link to={`/customers/${slug}`}>
      <div className="cs-text mb90 br10 hook-up position-relative">
        <Col lg={12}>
          <p className="text-pink p12 let-space-4 fw-500">CASE STUDY</p>
          <p className="title fw-bold font-roboto mb38 text-deep-purple">
            {" "}
            {title}{" "}
          </p>
          <p className="p24 fw-bold font-roboto name mb24 text-deep-purple">
            {chapterTitle}
          </p>
          <p className="link">
            download now
            <SVGIcon name="hypertext-arrow-right" className="arrow-right" />
          </p>
          <div className="position-absolute img-wrapper">
            <img
              src={image.url}
              className="w-100 cus-image"
              alt={chapterTitle}
            />
          </div>
        </Col>
      </div>
    </Link>
  )
}
const Review = ({ item }) => {
  return (
    <div className="reviewer-box mb24 br10 hook-up">
      <Col lg={12} className="font-roboto">
        <div className="d-flex justify-content-between mb30">
          <SVGIcon name="pinkQuoteSmall" />
          <div className="d-flex">
            <FiveStar />
          </div>
        </div>
        {item.excerpt.html && (
          <div className="fw-600 content">{parse(item.excerpt.html)}</div>
        )}
        {item.chapterTitle && (
          <div className="d-flex justify-content-between">
            <p className="p24 fw-bold name m-0">{item.chapterTitle}</p>
            <SVGIcon name="g2_logo_new" />
          </div>
        )}
      </Col>
    </div>
  )
}
const TaskAssigner = ({ item }) => {
  if (item.categoryDropdown === "review") return <Review item={item} />
  else {
    if (item.chapterNumber && item.chapterNumber.includes("youtube"))
      return <CaseStudyVideo item={item} />
    else return <CaseStudyText item={item} />
  }
}
const ParentCard = props => {
  const { allArray } = props
  let delayCount = 0

  return (
    <Row>
      {allArray.map((array, id) => {
        return (
          <Col lg={4}>
            {array.map(item => {
              if (delayCount > 5) {
                delayCount = 0
              }
              return (
                <Fade
                  bottom
                  delay={(delayCount + 1) * 130}
                  duration="800"
                  distance={"20px"}
                >
                  <span className="dspnone">{delayCount++}</span>
                  <TaskAssigner item={item} />
                </Fade>
              )
            })}
          </Col>
        )
      })}
    </Row>
  )
}

const Customers = () => {
  const [activeName, setActiveName] = useState("All")
  const scrollArrRef = useRef()

  useEffect(() => {
    window.addEventListener("scroll", handleVisibility)
    return function cleanup() {
      window.removeEventListener("scroll", handleVisibility)
    }
  }, [])

  const handleVisibility = () => {
    const BadgeSection = document.getElementById("cs-and-review-id")
    const screenHeight = window.innerHeight

    if (screenHeight > BadgeSection.getBoundingClientRect().top + 10) {
      scrollArrRef.current.style.opacity = 0
      scrollArrRef.current.style.visibility = "hidden"
    }

    const springWireTop = document
      .getElementsByClassName("spring-wire-wrapper")[0]
      .getBoundingClientRect().top
    if (springWireTop < 700) setWireVisible(true)
  }

  useEffect(() => {
    const BadgeSection = document.getElementById("cs-and-review-id")
    const screenHeight = window.innerHeight
    if (screenHeight < BadgeSection.offsetTop) {
      scrollArrRef.current.style.opacity = 1
      scrollArrRef.current.style.visibility = "visible"
    }
  }, [])

  const scrollToBadge = () => {
    window.scrollTo({
      top: window.innerHeight - 200,
      behavior: "smooth",
    })
    scrollArrRef.current.style.opacity = 0
    scrollArrRef.current.style.visibility = "hidden"
  }

  const [wireVisible, setWireVisible] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", handleVisibility)
    return function cleanup() {
      window.removeEventListener("scroll", handleVisibility)
    }
  }, [])

  return (
    <>
      <StaticQuery
        query={graphql`
          query customers {
            SuperOps {
              pages(where: { title: "Customer page" }) {
                heroSection {
                  tag
                  backgroundColor
                  heading {
                    text
                    html
                  }
                  content {
                    text
                  }
                  heroImage {
                    url
                  }
                }
                subtitle
                pageImages {
                  url
                }
                seo {
                  title
                  description
                  keywords
                  image {
                    url
                  }
                }
                newField {
                  ... on SuperOps_CtaFeature {
                    ctaBox {
                      type
                      theme
                      heading {
                        html
                      }
                      description {
                        html
                      }
                      primaryButtonText
                      primaryButtonLink
                    }
                  }
                }
              }
              resources(
                orderBy: createdAt_DESC
                where: { category: "customer page" }
              ) {
                categoryDropdown
                slug
                title
                excerpt {
                  html
                }
                image {
                  url
                }
                chapterNumber
                chapterTitle
              }
            }
          }
        `}
        render={data => (
          <>
            {data.SuperOps.pages.map(page => {
              const { seo, heroSection, subtitle, pageImages, newField } = page
              let navBgColor = heroSection.backgroundColor
              navBgColor = navBgColor.slice(
                navBgColor.indexOf("#"),
                navBgColor.indexOf("#") + 7
              )
              const darkColors = ["#FE7D7D", "#D67FBF", "#8E97E1"]
              const lightColors = ["#FFC3C3", "#FFD1F3", "#D4D9FF"]
              const eleven = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
              let resources = data.SuperOps.resources
              let one = [],
                two = [],
                three = [],
                checker = 1
              if (activeName !== "All") {
                resources =
                  activeName === "Case studies"
                    ? resources.filter(x => x.categoryDropdown === "case_study")
                    : resources.filter(x => x.categoryDropdown === "review")
              }

              resources.map((item, id) => {
                if (checker === 1) {
                  one.push(item)
                } else if (checker === 2) {
                  two.push(item)
                } else {
                  three.push(item)
                }
                checker += 1
                if (checker === 4) {
                  checker = 1
                }
              })

              return (
                <div className="customer-page">
                  <Frame seo={seo} newButton>
                    <header>
                      <Navigation
                        page="Home"
                        dskScroll={400}
                        darkHelloBar
                        theme="dark"
                        darkTheme
                        btn1="secondary white br-47"
                        btn2="primary white ml-9 br-47"
                      />
                    </header>

                    <section className="hero position-relative">
                      <div className="all-svgs d-dsk down-to-up-5">
                        {eleven.map(a => {
                          return (
                            <SVGIcon
                              name={`customers/cus_page_${a}`}
                              className={`position-absolute decoration-icon-${a}`}
                            />
                          )
                        })}
                      </div>
                      <Container className="Layout-container text-center">
                        <p className="tag text-pink let-space-4 mb24 down-to-up-1">
                          {heroSection.tag}
                        </p>
                        <h1 className="heading fw-bold font-roboto mx-auto mb30 down-to-up-2">
                          <div className="title-wrapper">
                            {parse(heroSection?.heading?.html)}
                          </div>
                        </h1>
                        <p className="p18 description mx-auto mb24 down-to-up-3">
                          {heroSection.content.text}
                        </p>
                        <div className="img-wrapper mx-auto down-to-up-4">
                          <img
                            src={heroSection.heroImage.url}
                            alt="user-rating"
                            className="w-100"
                          />
                        </div>
                        <div className="d-flex justify-content-center prelative d-dsk scroll-btn-wrap mt60">
                          <div
                            ref={scrollArrRef}
                            className={"scroll-btn position-absolute"}
                            onClick={() => scrollToBadge()}
                          >
                            <SVGIcon
                              name="block/downArrowRed"
                              className="down-arrow-red"
                            />
                          </div>
                        </div>
                      </Container>
                    </section>

                    <section
                      className="cs-review down-to-up-3 position-relative"
                      id="cs-and-review-id"
                    >
                      <Container className="Layout-container">
                        <div className="btn-wrapper">
                          <div className="d-dsk d-flex justify-content-center g24 mb90">
                            {["All", "Case studies", "Reviews"].map(
                              (name, i) => {
                                return (
                                  <button
                                    onClick={() => {
                                      setActiveName(name)
                                    }}
                                    className={`${
                                      activeName === name ? "active" : ""
                                    } category-button`}
                                    css={css`
                                      background: linear-gradient(
                                        90deg,
                                        ${darkColors[i]} -60%,
                                        rgba(255, 255, 255, 0) 80%
                                      );
                                      &.active {
                                        background: linear-gradient(
                                          90deg,
                                          ${darkColors[i]} 0%,
                                          ${lightColors[i]} 103.13%
                                        );
                                      }
                                    `}
                                  >
                                    <div className="button-name d-flex align-items-center justify-content-between fw-bold">
                                      {name.toUpperCase()}
                                      <SVGIcon
                                        name="home/rightArrBlack"
                                        className="right-arrow-black"
                                      />
                                    </div>
                                  </button>
                                )
                              }
                            )}
                          </div>

                          <div className="d-mob mb24">
                            <Dropdown>
                              <Dropdown.Toggle className="fw-bold">
                                {activeName.toUpperCase()}
                                <img
                                  src={EsArrowDown}
                                  height="1"
                                  width="1"
                                  alt="img"
                                />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => setActiveName("All")}
                                >
                                  <p>ALL</p>
                                </Dropdown.Item>
                                {["Case studies", "Reviews"].map(type => {
                                  return (
                                    <Dropdown.Item
                                      onClick={() => setActiveName(type)}
                                    >
                                      <p>{type.toUpperCase()}</p>
                                    </Dropdown.Item>
                                  )
                                })}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                        {activeName === "All" && (
                          <ParentCard allArray={[one, two, three]} />
                        )}
                        {activeName === "Case studies" && (
                          <ParentCard allArray={[one, two, three]} />
                        )}
                        {activeName === "Reviews" && (
                          <ParentCard allArray={[one, two, three]} />
                        )}
                      </Container>
                      <div className="d-dsk spring-wire-wrapper">
                        {wireVisible && (
                          <SVGIcon
                            name="block/pinkSpringWire"
                            className="spring-wire-v2 position-absolute w-100"
                          />
                        )}
                      </div>
                    </section>

                    <section className="badges-2023">
                      <Container className="Layout-container">
                        <div className="altv3">
                          <Fade
                            bottom
                            delay={60}
                            duration="800"
                            distance={"20px"}
                          >
                            <h1 className="font-roboto fw-bold heading mx-auto text-center mb48">
                              {subtitle}
                            </h1>
                          </Fade>
                          <Row className="justify-content-center">
                            <Col lg={8}>
                              <div className="d-flex flex-wrap justify-content-evenly gap-40">
                                {pageImages.map((img, ind) => {
                                  return (
                                    <Fade
                                      bottom
                                      delay={(ind + 1) * 60}
                                      duration="800"
                                      distance={"20px"}
                                    >
                                      <div className="img-wrapper">
                                        <img
                                          src={img.url}
                                          alt="badges"
                                          className="w-100"
                                        />
                                      </div>
                                    </Fade>
                                  )
                                })}
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Container>
                    </section>

                    <section>
                      <CTA
                        data={[newField[0].ctaBox]}
                        className="Layout-container"
                        maxDescriptionWidth="500px"
                        lgLeft={6}
                        lgRight={4}
                        newButton
                        newDesign
                      />
                    </section>
                  </Frame>
                </div>
              )
            })}
          </>
        )}
      />
    </>
  )
}

export default Customers
